import { graphql } from 'api/graphql'
import { User } from 'api/typings'
import { pathOr } from 'ramda'

export type UserProfile = Required<
  Pick<User, 'id' | 'firebaseId' | 'name' | 'email' | 'phoneNumber'>
> &
  Pick<User, 'profilePicUrl'>

export async function getUserProfile() {
  const response = await graphql(
    `me {
        id, uid, name, email, status, phoneNumber
    }`
  )

  return pathOr(null, ['data', 'data', 'me'], response) as UserProfile | null
}

export async function getUserProfileById(userId: number) {
  const response = await graphql(
    `admin {
      user (userId:${userId}) {
        id, uid, firebaseId, name, email, status, phoneNumber
        profilePicUrl
      }
    }`
  )

  return pathOr(
    null,
    ['data', 'data', 'admin', 'user'],
    response
  ) as UserProfile | null
}
