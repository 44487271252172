import React, { useEffect, useState } from 'react'
import { Roster, WorkShift } from '../../Shared/common_defs'
import { Box, Button, Card, Flex, Heading, Icon, Text } from '@workwhile/ui'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { workerAvailabilityKey } from 'queries/user/keys'
import { getWorkerAvailability } from 'api/user'
import { useQueryClient } from '@tanstack/react-query'
import { RequestedWorkerStatus } from 'components/Users/JobRequests/RequestedWorkerStatus'

interface Props {
  shift: WorkShift
  roster: Roster
  open?: boolean
}

const RosterWorkers = ({ roster, shift, open = false }: Props) => {
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(open)

  // Prepopulate worker availability before toggle is opened
  useEffect(() => {
    if (!open) {
      const shiftId = Number(shift.id)
      const workerIds = roster?.workers?.map((worker) => Number(worker.id))
      queryClient.prefetchQuery({
        queryKey: workerAvailabilityKey({
          shiftId,
          workerIds,
        }),
        queryFn: () =>
          getWorkerAvailability({
            shiftId,
            workerIds,
          }),
      })
    }
  }, [open])

  const content = roster?.workers?.map((worker) => (
    <Flex key={worker.id} flexWrap="wrap" justifyContent="space-between">
      <Text>
        {worker.name} ({worker.id})
      </Text>
      <Box ml={1}>
        <RequestedWorkerStatus worker={worker} shift={shift} />
      </Box>
    </Flex>
  ))

  if (!roster) return null

  if (open) {
    return (
      <>
        <Heading level="4">{`${roster.name} (id: ${roster.id}) (${roster.workers?.length} workers)`}</Heading>
        {content}
      </>
    )
  }

  return (
    <Box>
      <Button
        my="2"
        kind="small"
        block
        variant="secondary"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        {`${ isOpen ? 'Hide' : 'Show'} ${roster.name} (Roster ${roster.id}) (${roster.workers?.length} workers)`}
        <Icon icon={isOpen ? ChevronUp : ChevronDown} color="primary" />
      </Button>
      {isOpen && <Card mb="2">{content}</Card>}
    </Box>
  )
}

export default RosterWorkers
