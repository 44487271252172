import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getCompanies, GetCompaniesParams } from "api/company";

const QUERIES = {
    ids: `{
        id,
        name,
        companyGroupId
    }`
}

export default function (queryKey: keyof typeof QUERIES, filters: GetCompaniesParams, options?: Pick<UseQueryOptions, 'retry' | 'enabled'>) {
    return useQuery({
        queryKey: ['company', queryKey],
        queryFn: async () => await getCompanies(filters, QUERIES[queryKey]),
        placeholderData: [],
        ...options
    })
}