import React, { useState } from 'react'
import WorkerList from './WorkerList'
import Loading from '../../Shared/loading'
import AssignShift from '../Workers/assign_shift'
import { WorkShift } from '../../Shared/common_defs'
import RefreshButton from '../../Shared/RefreshButton'
import { defs } from '../../Shared/defs'
import { useShiftDetails } from 'queries/shifts/useShiftDetails'
import { Box, Button, Card, Flex, Heading, Icon, Text } from '@workwhile/ui'
import { ChevronDown, ChevronUp, ExternalLink } from 'lucide-react'
import moment from 'moment'
import RosterWorkers from './RosterWorkers'
import { calculateFillPercent } from '../Search'

interface Props {
  shift: WorkShift
}

const Workers = ({ shift }: Props) => {
  const [showActions, setShowActions] = useState(false)

  const fill = useShiftDetails(shift.id, 'fill')

  if (fill.isFetching) {
    return <Loading type="button" />
  }

  if (!fill.data?.rosters) {
    return <p>Could not find info on workers for this shift.</p>
  }

  const uniqueWorkers = fill.data?.rosters?.reduce((agg, roster) => {
    roster.workers.forEach(({ id }) => {
      agg.add(id)
    })

    return agg
  }, new Set())

  const unfilledCount = fill.data.workersNeeded + fill.data.numCushionWorkersNeeded - fill.data.numWorkersScheduled

  return (
    <Box>
      <RefreshButton
        loading={fill.isFetching}
        refreshFn={fill.refetch}
        style={{ float: 'right' }}
      />

      <Box>
        <Box>
          <Heading level="3">{`Requested: ${fill.data.workersNeeded} + ${fill.data.numCushionWorkersNeeded}`}</Heading>
          <Heading level="3">
            {`Scheduled: ${fill.data.numWorkersScheduled}`}
          </Heading>
          {unfilledCount > 0 && (
            <Heading
              level="3"
              color="red"
            >{`${unfilledCount} Unfilled • ${calculateFillPercent(fill.data)}% Full`}</Heading>
          )}
        </Box>

        {uniqueWorkers.size > 0 && (
          <Text>{`* Restricted to ${uniqueWorkers.size} worker${uniqueWorkers.size === 1 ? '' : 's'} ${
            fill.data?.rostersHoldExpiresAt
              ? `until ${moment
                  .tz(fill.data?.rostersHoldExpiresAt, moment.tz.guess(true))
                  .format('ddd MMM Do, h:mma z')}`
              : `indefinitely`
          }`}</Text>
        )}

        {(fill.data?.rosters || []).map((roster) => (
          <RosterWorkers key={roster.id} roster={roster} shift={shift} />
        ))}

        <Text my="2">
          <Text>{`STARTED: ${fill.data.numWorkersStarted} | COMPLETED: ${fill.data.numWorkersFinished}`}</Text>
          <Text>{`REJECTED: ${fill.data.numWorkersRejected} | REMOVED: ${fill.data.numWorkersRemoved}`}</Text>
        </Text>
      </Box>

      <Flex flexDirection="column">
        {!shift.leadShifts || shift.leadShifts.length === 0
          ? null
          : shift.leadShifts.map((leadShift, index) => (
              <Box key={index} style={{ marginBottom: defs.marginL }}>
                <Text as="strong">Shift Lead Shift {leadShift.id}</Text>
                <WorkerList
                  shift={leadShift}
                  fetchWorkStats={fill.refetch}
                  open
                />
              </Box>
            ))}

        <WorkerList shift={shift} fetchWorkStats={fill.refetch} open />

        <Button
          mt="2"
          kind="small"
          block
          variant="secondary"
          onClick={() => setShowActions(!showActions)}
          type="button"
        >
          {showActions ? 'Hide' : 'Show'} actions
          <Icon icon={showActions ? ChevronUp : ChevronDown} color="primary" />
        </Button>
        {showActions && (
          <Card mt="2">
            <AssignShift shiftId={shift.id} callback={fill.refetch} />
          </Card>
        )}

        <Button
          block
          kind="small"
          mt="2"
          variant="secondary"
          onClick={() =>
            window.open(`/shift_analytics?shiftId=${shift.id}`, '_blank')
          }
          type="button"
        >
          Show shift fill analytics{' '}
          <Icon
            color="primary"
            height="20px"
            icon={ExternalLink}
            style={{ marginLeft: defs.marginS }}
            width="20px"
          />
        </Button>
      </Flex>
    </Box>
  )
}

export default Workers
