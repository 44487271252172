import React from 'react'
import { Loading } from '@workwhile/ui'
import { useUserProfileById, useUserW2I9Form } from 'queries/user'
import { I9Form } from '../I9Form'

export function DetailView({ userId }: { userId: number }) {
  const { data: user, isLoading: isLoadingUserProfile } =
    useUserProfileById(userId)
  const { data: userW2I9Form, isLoading: isLoadingUserW2I9Form } =
    useUserW2I9Form(userId)

  if (isLoadingUserProfile || isLoadingUserW2I9Form) {
    return <Loading />
  }

  return <I9Form user={user} data={userW2I9Form} showProcessForm />
}
