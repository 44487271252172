import React from 'react'
import { Avatar, Box, Button, Flex, Heading, Loading } from '@workwhile/ui'
import { useUserProfileById, useUserW2I9Form } from 'queries/user'
import UserHeader from 'components/Users/UserHeader'
import { User } from 'api/typings'
import { I9Form } from './I9Form'

export function UserDetails({ userId }: { userId: number }) {
  const { data: user, isLoading: isLoadingUserProfile } =
    useUserProfileById(userId)
  const { data: i9Form, isLoading: isLoadingI9Form } = useUserW2I9Form(userId)

  if (isLoadingUserProfile || isLoadingI9Form) {
    return <Loading />
  }

  return (
    <Box px={4}>
      <Box>
        <UserHeader user={user as unknown as User} />
      </Box>
      <I9Form user={user} data={i9Form} />
    </Box>
  )
}
