import { useQuery } from '@tanstack/react-query'
import api from 'api'
import { WorkShift } from 'api/typings'

export type SupplyAnalytics = {
  /** Number of users whose address is within the given radius of the position location
   * and user status is 'active', 'account_created', or 'suspended'
   * and user is not an internal user
   * and the shift market is in the user's markets
   * and the user's checkr_status is 'clear', 'manually_clear', or null
   */
  total_users: number
  /** App user status is active */
  status_active: number
  /** App user status is account_created */
  status_account_created: number
  /** App user status is suspended */
  status_suspended: number
  /** App user was last seen within 30 days ago */
  active_30_days: number
  /** Company blocked the current app user */
  blocked_by_company: number
  /** Company hired the current app user */
  hired_by_company: number
  /** App user declined the current listing due to 'company' */
  declined_company: number
  /** App user declined the current listing due to 'location' */
  declined_location: number
  /** App user declined the current listing due to 'other' */
  declined_other: number
  /** App user declined the current listing due to 'pay' */
  declined_pay: number
  /** App user declined the current listing due to 'schedule' */
  declined_schedule: number
  /** App user declined the current listing due to 'shift_too_long' */
  declined_shift_too_long: number
  /** App user declined the current listing due to 'work_type' */
  declined_work_type: number
  /** App user declined the current listing due to 'workwhile' */
  declined_workwhile: number
  /** App user has a covid vaccine credential */
  has_covid_vaccine_credential: number
  /** App user has a drivers license credential */
  has_drivers_license_credential: number
  /** App user has a fork lift credential */
  has_fork_lift_credential: number
  /**
   * worker_shift_assocs.status === 'scheduled'
   * and assoc shift starts before current shift ends
   * and ends after current shift starts
   */
  has_overlapping_shift: number
  /** user_vehicle.size_category in ('suv', 'minivan') */
  has_suv: number
  /** App user has a vehicle insurance credential */
  has_vehicle_insurance_credential: number
  /** App user has a vehicle with a license plate id */
  has_vehicle: number
  /** App user's W2 status is active and in the same state as the current listing */
  w2_active: number
  /** u.w2_status IN ('eligible', 'approved', 'submitted') */
  w2_eligible: number
  /** App user has an Active W2 status, but not in the current listing's state */
  w2_out_of_state: number
  /** App user's date of birth was over 21 years ago */
  is_over_21: number
  /** App user does not have a date of birth on record */
  missing_dob: number
  /** App user is shadow blocked */
  shadow_blocked: number
  shift_id: WorkShift['id']
  /** User is not hired or blocked by the company
   * If work_shift.needs_w2, user's w2_status is active and in the same state as the shift
   * If work_shift.needs_license, user has 'drivers_license' credential
   * If work_shift.needs_car, user has a license_plate_id
   * If work_shift.needs_suv, user has an suv or minivan
   * If work_shift.needs_forklift, user has a 'fork_lift' credential
   * User has no overlapping shifts
   */
  total_eligible: number
  /** Every 25 miles from 0 to 300 */
  r_from?: number
  /** Every 25 miles from 24 to 324 */
  r_to?: number
  /** Number of users eligible for the shift roster tier */
  tier_roster: number
  /** Number of users eligible for the experienced tier */
  tier_experienced: number
  /** Number of users eligible for the all tier */
  tier_all: number
  /** Number of users eligible for the favorite tier */
  tier_favorite: number
  /** Number of users eligible for the priority tier */
  tier_priority: number
  /** Fulfilled must-have requirements from worker_requirements and company_position */
  must_have_requirements: Record<string, number>
}

export function useSupplyAnalytics(shiftId: WorkShift['id'] | string) {
  return useQuery<SupplyAnalytics[]>({
    queryKey: ['shift', shiftId, 'supplyAnalytics'],
    queryFn: async () =>
      api.get(`/admin/shift/${shiftId}/supply`).then((result) => result.data),
    enabled: !!shiftId,
    retry: false,
  })
}
