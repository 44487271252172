import { useMutation } from '@tanstack/react-query'
import { processUserI9Documents } from 'api/everify'

export function useProcessI9Documents({
  userId,
  ...options
}: {
  userId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  return useMutation({
    mutationFn: () => processUserI9Documents(userId),
    ...options,
  })
}
