import React from 'react'
import { createRoot } from 'react-dom/client'
import { Pages } from './pages'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider, TooltipProvider } from '@workwhile/ui'
import { AuthProvider } from 'components/auth'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <TooltipProvider delayDuration={500}>
          <AuthProvider>
            <Pages />
          </AuthProvider>
        </TooltipProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
