import {
  Box,
  Button,
  Card,
  Field,
  Heading,
  Input,
  Message,
} from '@workwhile/ui'
import { UserW2I9Form } from 'api/everify'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { approvalFormSchema, ApprovalForm as ApprovalFormType } from './schemas'
import { useAcceptI9Documents, useInvalidateUserW2I9Form } from 'queries/user'
import { UserProfile } from 'api/user'
import { showErrorToast } from 'components/utils/util'
import { format } from 'date-fns'

export function ApprovalForm({
  user,
  data,
}: {
  user: UserProfile
  data: UserW2I9Form
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ApprovalFormType>({
    resolver: zodResolver(approvalFormSchema),
    defaultValues: {
      documentListType: data.documentListType,
      doc1: {
        expiryDate: '',
        title: '',
        issuingAuthority: '',
        documentNumber: '',
      },
      doc2:
        data.documentListType !== 'list_a'
          ? { issuingAuthority: '', documentNumber: '', expiryDate: '', title: '' }
          : undefined,
    },
  })

  const invalidateI9Form = useInvalidateUserW2I9Form()
  const { mutate: acceptDocuments, isPending } = useAcceptI9Documents({
    userId: Number(user.id),
    onSuccess: () => {
      invalidateI9Form(Number(user.id))
    },
    onError: (err: Error) => {
      showErrorToast(err.message)
    },
  })

  const onSubmit = (formData: ApprovalFormType) => {
    acceptDocuments({
      doc1: formData.doc1,
      doc2: formData.doc2,
    })
  }

  return !!data && (
    <Card>
      <Heading my={0} mb={2} level={3}>
        Approval
      </Heading>
      {data.verifiedAt ? (
        <Message variant={'success'}>
          I9 Docs verified at {format(data.verifiedAt, 'PPpp')}
        </Message>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Heading level={4}>Document 1</Heading>
            <Field
              label={'Document Title'}
              error={errors.doc1?.title?.message}
            >
              <Controller
                name="doc1.title"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Field>
            <Field
              label={'Issuing Authority'}
              error={errors.doc1?.issuingAuthority?.message}
            >
              <Controller
                name="doc1.issuingAuthority"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Field>
            <Field
              label={'Document Number'}
              error={errors.doc1?.documentNumber?.message}
            >
              <Controller
                name="doc1.documentNumber"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Field>
            <Field
              label={'Expiry Date (if specified)'}
              error={errors.doc1?.expiryDate?.message}
            >
              <Controller
                name="doc1.expiryDate"
                defaultValue={''}
                control={control}
                render={({ field }) => <Input {...field} type="date" />}
              />
            </Field>
          </Box>
          {data.documentListType !== 'list_a' ? (
            <Box mb={2}>
              <Heading level={4}>Document 2</Heading>
              <Field
                label={'Document Title'}
                error={errors.doc2?.title?.message}
              >
                <Controller
                  name="doc2.title"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Field>
              <Field
                label={'Issuing Authority'}
                error={errors.doc2?.issuingAuthority?.message}
              >
                <Controller
                  name="doc2.issuingAuthority"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Field>
              <Field
                label={'Document Number'}
                error={errors.doc2?.documentNumber?.message}
              >
                <Controller
                  name="doc2.documentNumber"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </Field>
              <Field
                label={'Expiry Date (if specified)'}
                error={errors.doc2?.expiryDate?.message}
              >
                <Controller
                  name="doc2.expiryDate"
                  defaultValue={''}
                  control={control}
                  render={({ field }) => <Input {...field} type="date" />}
                />
              </Field>
            </Box>
          ) : null}
          <Button type="submit" loading={isPending}>
            Approve I9 Documents
          </Button>
        </form>
      )}
    </Card>
  )
}
