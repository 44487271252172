import React from 'react'
import { Box, Button } from '@workwhile/ui'
import { RefreshCcw } from 'lucide-react'


type Props = {
  refreshFn: () => void,
  loading: boolean,
} & React.ComponentProps<typeof Box>['style']

const RefreshButton = ({ refreshFn, loading, ...props }: Props) => {
  return (
    <Button
      ml="1"
      kind="small"
      variant="text"
      color="secondary"
      onClick={refreshFn}
      alignSelf="center"
      loading={loading}
      {...props}
    >
      {!loading && <RefreshCcw /> }
    </Button>
  )
}

export default RefreshButton
