import { useQuery } from '@tanstack/react-query'
import { getUserW2I9Form } from 'api/everify/user_w2'
import { userW2I9FormKey } from './keys'
import { useQueryClient } from '@tanstack/react-query'

export const useUserW2I9Form = (userId: number) => {
  return useQuery({
    queryKey: userW2I9FormKey(userId),
    queryFn: () => getUserW2I9Form(userId),
  })
}

export const useInvalidateUserW2I9Form = () => {
  const queryClient = useQueryClient()

  return function invalidateW2I9Form(userId: number) {
    queryClient.invalidateQueries({
      queryKey: userW2I9FormKey(userId),
    })
  }
}
