import React, { useState, useEffect, useCallback } from 'react';
import api from '../api';
import { useInView } from 'react-intersection-observer';
import './UpcomingListings.css'; // Add this import for custom styles

// These TypeScript interfaces match the pydantic models defining the request/response schema in the backend (backend/api/v1/admin/listing.py)
interface TierWithStats {
    tier: string;
    planned_start_time?: string;
    actual_start_time?: string;
    predicted_num_scheduled_workers: number;
}

interface ShiftDetails {
    id: number;
    start_time: string;
    end_time: string;
    workers_needed: number;
}

interface ListingDetails {
    id: number;
    company_id: number;
    company_name: string;
    current_tier_v1: string;
    current_tier_v2: string;
    tier_v2_schedule?: TierWithStats[];
    shifts: ShiftDetails[];
    created_at: string;
}

interface ListingsResponse {
    count: number;
    listings: ListingDetails[];
    next_cursor?: number;
}

const initialFilters = {
    company_ids: '',
    worker_ids: '',
    listing_ids: '',
    market: '',
    start_date: '',
    end_date: '',
    tier: '', // Added tier filter
    tier_v2: '', // Added tier_v2 filter
};

const UpcomingListings: React.FC = () => {
    const [filters, setFilters] = useState(initialFilters);
    const [lastAppliedFilters, setLastAppliedFilters] = useState(initialFilters);
    const [listings, setListings] = useState<ListingDetails[]>([]);
    const [loading, setLoading] = useState(false);
    const [nextCursor, setNextCursor] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState(false);

    const { ref, inView } = useInView({
        threshold: 0.1, // Trigger when 10% of the sentinel is visible
    });

    // Handle input changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFilters = {
            ...filters,
            [e.target.name]: e.target.value,
        };
        setFilters(newFilters);
        setIsDirty(JSON.stringify(newFilters) !== JSON.stringify(lastAppliedFilters));
        console.log('Filters changed:', newFilters);
    };

    // Fetch listings from the API
    const fetchListings = useCallback(async (cursor?: number) => {
        if (loading) {
            console.log('Fetch prevented: already loading');
            return; // Prevent multiple fetches
        }
        setLoading(true);
        setError(null);
        console.log(`Fetching listings with cursor: ${cursor}`);
        console.log('Applied filters:', filters);
        try {
            const params: any = {
                company_ids: filters.company_ids ? filters.company_ids.split(',').map(id => Number(id.trim())) : undefined,
                worker_ids: filters.worker_ids ? filters.worker_ids.split(',').map(id => Number(id.trim())) : undefined,
                listing_ids: filters.listing_ids ? filters.listing_ids.split(',').map(id => Number(id.trim())) : undefined,
                market: filters.market || undefined,
                start_date: filters.start_date || undefined,
                end_date: filters.end_date || undefined,
                tier: filters.tier ? filters.tier.split(',').map(t => t.trim()) : undefined, // Added tier filter
                tier_v2: filters.tier_v2 ? filters.tier_v2.split(',').map(t => t.trim()) : undefined, // Added tier_v2 filter
                cursor: cursor ? cursor : undefined,
                limit: 100,
            };

            const response = await api.get<ListingsResponse>('/admin/upcoming_listings', { params });
            console.log('Fetch successful:', response.data);
            setListings(prevListings => cursor ? [...prevListings, ...response.data.listings] : response.data.listings);
            setNextCursor(response.data.next_cursor);
        } catch (error) {
            console.error('Error fetching listings:', error);
            setError('Failed to load more listings.');
        } finally {
            setLoading(false);
        }
    }, [filters, loading]);

    // Handle form submission
    const handleSubmit = async () => {
        setListings([]);
        setNextCursor(undefined);
        setLastAppliedFilters(filters);
        setIsDirty(false);
        console.log('Form submitted: fetching initial listings');
        console.log('Filters being applied:', filters);
        await fetchListings();
    };

    // Fetch more listings when the sentinel comes into view
    useEffect(() => {
        if (inView && !isDirty && listings.length > 0) {
            console.log('Sentinel is in view');
            if (nextCursor) {
                fetchListings(nextCursor);
            } else {
                console.log('No next cursor available');
            }
        }
    }, [inView, nextCursor, fetchListings, isDirty, listings.length]);

    return (
        <div className="listings-container">
            <h1 className="header">Upcoming Listings</h1>
            <form className="filter-form">
                {/* Form Fields */}
                <div className="form-group">
                    <label>Company IDs (comma separated):</label>
                    <input type="text" name="company_ids" value={filters.company_ids} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>Worker IDs (comma separated):</label>
                    <input type="text" name="worker_ids" value={filters.worker_ids} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>Listing IDs (comma separated):</label>
                    <input type="text" name="listing_ids" value={filters.listing_ids} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>Market:</label>
                    <input type="text" name="market" value={filters.market} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>Start Date:</label>
                    <input type="date" name="start_date" value={filters.start_date} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>End Date:</label>
                    <input type="date" name="end_date" value={filters.end_date} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>Tier (comma separated):</label>
                    <input type="text" name="tier" value={filters.tier} onChange={handleChange} className="input-field" />
                </div>
                <div className="form-group">
                    <label>Tier V2 (comma separated):</label>
                    <input type="text" name="tier_v2" value={filters.tier_v2} onChange={handleChange} className="input-field" />
                </div>
            </form>

            {/* Submit button moved outside the form and centered */}
            <div className="submit-button-container">
                <button onClick={handleSubmit} className="submit-button">
                    {listings.length === 0 ? 'Fetch Listings' : (isDirty ? 'Apply Filters' : 'Refresh Listings')}
                </button>
                {isDirty && <span className="dirty-indicator">Filters have changed. Click to apply.</span>}
            </div>

            {/* Listings Table */}
            {listings.length > 0 ? (
                <table className="listings-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Company ID</th>
                            <th>Company Name</th>
                            <th>Current Tier V1</th>
                            <th>Current Tier V2</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings.map((listing) => (
                            <tr key={listing.id}>
                                <td>{listing.id}</td>
                                <td>{listing.company_id}</td>
                                <td>{listing.company_name}</td>
                                <td>{listing.current_tier_v1}</td>
                                <td>{listing.current_tier_v2}</td>
                                <td>{listing.created_at}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                !loading && <p className="no-listings">No listings available. Click 'Fetch Listings' to load data.</p>
            )}

            {/* Sentinel Element for Intersection Observer */}
            {!isDirty && listings.length > 0 && <div ref={ref} style={{ height: '20px' }}></div>}

            {/* Loading Indicator */}
            {loading && <p className="loading">Loading more listings...</p>}

            {/* Error Message */}
            {error && <p className="error-message">{error}</p>}

            {/* Notification for changed filters */}
            {isDirty && listings.length > 0 && (
                <p className="filter-notification">
                    Filters have changed. Click 'Apply Filters' to update the listings.
                </p>
            )}
        </div>
    );
};

export default UpcomingListings;