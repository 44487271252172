import {
  Call,
  CallControls,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
} from '@stream-io/video-react-sdk'
import React, { useEffect, useState } from 'react'
import { useEVerify } from './useEVerify'
import '@stream-io/video-react-sdk/dist/css/styles.css'

export function VideoBox({ callId }: { callId: string }) {
  const { client } = useEVerify()
  const [call, setCall] = useState<Call>()

  useEffect(() => {
    const call = client.call('default', callId)
    call.join()
    setCall(call)
  }, [])

  useEffect(() => {
    if (call) {
      return () => {
        setCall(undefined)
        call.leave().catch(() => console.log('error leaving room')) // do nothing
      }
    }
  }, [call])

  if (!call || !client) {
    return null
  }

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <StreamTheme>
          <SpeakerLayout />
          <CallControls />
        </StreamTheme>
      </StreamCall>
    </StreamVideo>
  )
}
