import graphql from 'graphql'
import { path } from 'ramda'
import { Company, Location } from 'api/typings'

export async function getCompanyLocations(companyId: number) {
  const response = await graphql(`
      admin { 
        companies(ids: [${companyId}]) {
            locations {
                id, name, address {city, state, street, unit, zip, lat, long, timezone}
            }
        }
      }`)

  return path(
    ['data', 'data', 'admin', 'companies', 0, 'locations'],
    response
  ) as Location[]
}

export type GetCompaniesParams = { grouping?: 'upcoming' | 'past' | 'ongoing' }
type GetCompaniesResult = {
  data: { admin: { companiesForFilters: Company[] } }
}
export async function getCompanies(params: GetCompaniesParams, query?: string) {
  return graphql<GetCompaniesResult>(`
    admin { 
        companiesForFilters (grouping: "${params.grouping || 'upcoming'}") ${query}
    }`).then((response) => response.data.data.admin.companiesForFilters)
}
