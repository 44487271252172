import React, { useState } from 'react'
import { WorkShift } from '../../Shared/common_defs'
import Loading from '../../Shared/loading'
import { isShiftFull, isShiftInProgress } from '../../utils/general_util'
import { Box, Button, Card, Flex, Icon, Label, Text } from '@workwhile/ui'
import RefreshButton from 'components/Shared/RefreshButton'
import moment from 'moment'
import { useShiftDetails } from 'queries/shifts'
import { ChevronDown, ChevronUp, ExternalLink } from 'lucide-react'
import ListingNotify from '../Listings/ListingNotify'
import ListingNextTier from '../Listings/ListingNextTier'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import {
  SupplyAnalytics,
  useSupplyAnalytics,
} from 'queries/shifts/useSupplyAnalytics'
import { useWorkerEligibility } from 'queries/shifts/useWorkerEligibility'
import { defs } from 'components/Shared/defs'

const jsonReadyString = (value) =>
  value ? JSON.parse(value?.replace(/'/g, '"')) : ''

const CellItem = ({
  label,
  value,
  alwaysDisplay,
}: {
  label: string
  value: number
  alwaysDisplay?: boolean
}) =>
  !!value || alwaysDisplay ? (
    <Flex
      justifyContent="space-between"
      py="1px"
      style={{ borderBottom: '1px solid #eee' }}
    >
      <Text>{label}</Text>
      <Text>{value}</Text>
    </Flex>
  ) : null

const columns: (
  position: WorkShift['position']
) => ColumnDescription<SupplyAnalytics>[] = (position) =>
  [
    {
      dataField: 'shift_id',
      text: 'Range',
      formatter: (cell, row) => `${row.r_from} - ${row.r_to + 1} miles`,
    },
    {
      dataField: 'total_users',
      text: 'Workers',
      formatter: (cell, row) => (
        <Box>
          <CellItem label="Total" value={cell} alwaysDisplay />
          <CellItem label="Eligible" value={row.total_eligible} alwaysDisplay />
        </Box>
      ),
    },
    {
      dataField: 'status_active',
      text: 'Status',
      formatter: (cell, row) => (
        <Box>
          <CellItem
            label="Recently online"
            value={row.active_30_days}
            alwaysDisplay
          />
          <CellItem label="Active" value={row.status_active} alwaysDisplay />
          <CellItem
            label="Account created"
            value={row.status_account_created}
            alwaysDisplay
          />
          <CellItem
            label="Suspended"
            value={row.status_suspended}
            alwaysDisplay
          />
        </Box>
      ),
    },
    {
      dataField: 'active_30_days',
      text: 'Availability',
      formatter: (cell, row) => (
        <Box>
          <CellItem label="Shadow blocked" value={row.shadow_blocked} />
          <CellItem label="Worker hired" value={row.hired_by_company} />
          <CellItem label="Worker blocked" value={row.blocked_by_company} />
          <CellItem
            label="Overlapping shift"
            value={row.has_overlapping_shift}
          />
          <CellItem label="Declined • Company" value={row.declined_company} />
          <CellItem label="Declined • Location" value={row.declined_location} />
          <CellItem label="Declined • Pay" value={row.declined_pay} />
          <CellItem label="Declined • Schedule" value={row.declined_schedule} />
          <CellItem
            label="Declined • Shift Too Long"
            value={row.declined_shift_too_long}
          />
          <CellItem
            label="Declined • Work Type"
            value={row.declined_work_type}
          />
          <CellItem
            label="Declined • WorkWhile"
            value={row.declined_workwhile}
          />
          <CellItem label="Declined • Other" value={row.declined_other} />
        </Box>
      ),
    },
    position?.needsW2 && {
      dataField: 'w2_active',
      text: 'W2',
      formatter: (cell, row) => (
        <Box>
          <CellItem label="Active" value={row.w2_active} />
          <CellItem label="Eligible" value={row.w2_eligible} />
          <CellItem label="Out of state" value={row.w2_out_of_state} />
        </Box>
      ),
    },
    position?.mustHaveRequirements?.some((req) => req.name.includes('21')) && {
      dataField: 'is_over_21',
      text: 'Age',
      formatter: (cell, row) => (
        <Box>
          <CellItem label="Age 21+" value={row.is_over_21} />
          <CellItem label="Missing DOB" value={row.missing_dob} />
        </Box>
      ),
    },
    !!position?.mustHaveRequirements?.length && {
      dataField: 'must_have_requirements',
      text: 'Fulfilled requirements',
      formatter: (cell, row) => (
        <Box>
          {Object.entries<number>(cell).map(([key, value]) => (
            <CellItem label={key} value={value} />
          ))}
        </Box>
      ),
    },
  ].filter(Boolean)

interface Props {
  shiftId: WorkShift['id']
}

const Listings = ({ shiftId }: Props) => {
  const [isRelative, setIsRelative] = useState(true)
  const [showActions, setShowActions] = useState(false)
  const [expandSupply, setExpandSupply] = useState(false)

  const shift = useShiftDetails(shiftId, 'listingTiers')
  const supplyAnalytics = useSupplyAnalytics(shiftId)
  const workerEligibility = useWorkerEligibility(shiftId)

  if (shift.isLoading) {
    return <Loading type="button" />
  }

  if (shift.isFetched && !shift.data?.listings) {
    return <Text>Could not find listings info for this shift.</Text>
  }

  const shiftFull = isShiftFull(shift.data)

  const inProgress = isShiftInProgress(shift.data)

  const listings =
    shift.data?.listings || (shift.data?.listing && [shift.data.listing]) || []

  const isListingInTempRosterHold = (listing) =>
    listing.tier === 'roster' && shift.data?.rostersHoldExpiresAt

  const durationTillRosterHoldReleased = shift.data?.rostersHoldExpiresAt
    ? moment.duration(moment(shift.data?.rostersHoldExpiresAt).diff(moment()))
    : null

  const totalMinutes = durationTillRosterHoldReleased
    ? Math.floor(durationTillRosterHoldReleased.asMinutes())
    : null

  const hours =
    totalMinutes && totalMinutes > 0 ? Math.floor(totalMinutes / 60) : null

  const minutes = totalMinutes && totalMinutes > 0 ? totalMinutes % 60 : null

  const tierTransitions = (listing) =>
    Object.entries<Date>(jsonReadyString(listing.tierTransitions)).reduce(
      (all, [tier, ts]) => [
        ...all,
        {
          tier,
          startsAt: ts,
          eligible: supplyAnalytics.data?.[0]?.[`tier_${tier}`],
        },
      ],
      []
    )

  const navigateToListing = () => {
    window.open(
      'https://console.cloud.google.com/logs/query;query=resource.type%3D%22gae_app%22%0Alog_name%3D%22projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured%22%0AjsonPayload.message.type%3D%22listing%22%0AjsonPayload.message.listing_id%3D%22${listing.id}%22;timeRange=P7D;summaryFields=jsonPayload%252Fmessage%252Flisting_id,jsonPayload%252Fmessage%252Fsub_type,jsonPayload%252Fmessage%252Fuser_id,jsonPayload%252Fmessage%252Ffrom_tier,jsonPayload%252Fmessage%252Fto_tier:false:32:beginning?project=workwhile-247820',
      '_blank'
    )
  }

  const timezone =
    shift.data?.location?.address?.timezone || moment.tz.guess(true)

  return (
    <div>
      {listings?.map((listing) => (
        <Box key={listing.id}>
          <Flex justifyContent="space-between">
            <Text>Listing ({listing.id})</Text>
            <Button
              kind="small"
              variant="text"
              onClick={navigateToListing}
              disabled={!listing.id}
            >
              View logs
              <Icon
                style={{ marginLeft: '5px' }}
                size={15}
                color="primary"
                icon={ExternalLink}
              />
            </Button>
          </Flex>
          <Flex justifyContent="space-between" my="2" flexDirection={['column', 'column', 'column', 'row']} style={{ columnGap: defs.marginM }}>
            <Box flex="1 1 auto">
              <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Text>
                  Total in market: {supplyAnalytics.data?.[0]?.total_users}
                </Text>
                <RefreshButton
                  refreshFn={supplyAnalytics.refetch}
                  loading={supplyAnalytics.isFetching}
                />
              </Flex>
              <Text>
                Eligible in market: {supplyAnalytics.data?.[0]?.total_eligible}
              </Text>
              <Text>Eligible in <Text as="strong">{listing.tier}</Text> tier: {workerEligibility.data?.eligible}</Text>
            </Box>
            <Box flex="2 1 auto">
              <Flex alignItems="center" justifyContent="space-between">
                <Text>
                  {`Top ineligibility reasons for `}
                  <Text as="strong">{listing.tier}</Text>
                  {` tier`}
                  {workerEligibility.data?.listing_radius ? (
                    <Text as="strong">
                      {` within ${workerEligibility.data?.listing_radius} mi `}
                    </Text>
                  ) : (
                    ''
                  )}
                  <Text as="span">:</Text>
                </Text>
                <RefreshButton
                  refreshFn={workerEligibility.refetch}
                  loading={workerEligibility.isFetching}
                />
              </Flex>
              <Box key="comprehensiveChecks">
                {workerEligibility.data && (
                  <>
                    {Object.entries(
                      workerEligibility.data?.ineligibility_reasons || {}
                    )
                      .sort((a, b) => b[1] - a[1])
                      .slice(0, 5)
                      .map(([reason, count]) => (
                        <Flex
                          ml="2"
                          justifyContent="space-between"
                          key={reason}
                        >
                          <Text ml="2" key={reason}>
                            {reason}
                          </Text>
                          <Text ml="2">{count}</Text>
                        </Flex>
                      ))}
                  </>
                )}
              </Box>
            </Box>
          </Flex>
          <BootstrapTable
            keyField="tier"
            data={tierTransitions(listing)}
            columns={[
              {
                dataField: 'tier',
                text: 'Tier',
                formatter: (cell) => (
                  <Flex>
                    <Text mr="2" as={cell === listing.tier ? 'strong' : 'span'}>
                      {cell}{' '}
                    </Text>
                    {cell === listing.tier && (
                      <Label size="small">active</Label>
                    )}
                  </Flex>
                ),
              },
              {
                dataField: 'startsAt',
                text: 'Start time',
                formatter: (cell, row) => (
                  <Text onClick={() => setIsRelative(!isRelative)}>
                    {isRelative
                      ? moment.tz(cell, timezone).fromNow()
                      : moment
                          .tz(cell, timezone)
                          .format('ddd MMM Do, h:mma zz')}
                  </Text>
                ),
              },
              { dataField: 'eligible', text: 'Total eligible *' },
            ]}
          />

          {!shiftFull && !inProgress && (
            <Box>
              <Button
                my="2"
                type="button"
                onClick={() => setShowActions(!showActions)}
                variant="secondary"
                kind="small"
                block
              >
                {showActions ? 'Hide' : 'Show'} actions{' '}
                <Icon
                  color="primary"
                  icon={showActions ? ChevronUp : ChevronDown}
                />
              </Button>

              {showActions && (
                <Card mb="2">
                  <ListingNotify
                    listing={listing}
                    fetchListings={shift.refetch}
                  />
                  <hr />
                  <ListingNextTier
                    listing={listing}
                    fetchListings={shift.refetch}
                  />
                </Card>
              )}
            </Box>
          )}
          {isListingInTempRosterHold(listing) && hours && minutes ? (
            <Text as="strong">
              Listing will exit roster tier in {hours}h {minutes}m
            </Text>
          ) : null}
          {!!supplyAnalytics.data?.length && (
            <Box>
              {
                <BootstrapTable
                  keyField={'r_from'}
                  data={supplyAnalytics.data?.slice(0, 1)}
                  columns={columns(shift.data?.position)}
                />
              }
              <Flex justifyContent="end">
                <Button
                  mb="2"
                  kind="small"
                  variant="secondary"
                  block
                  onClick={() => setExpandSupply(!expandSupply)}
                >
                  {expandSupply
                    ? 'Hide distance breakdown'
                    : 'Show distance breakdown'}{' '}
                  <Icon
                    color="primary"
                    icon={expandSupply ? ChevronUp : ChevronDown}
                  />
                </Button>
              </Flex>
              {expandSupply && (
                <BootstrapTable
                  keyField={'r_from'}
                  data={supplyAnalytics.data.slice(1)}
                  columns={columns(shift.data?.position)}
                />
              )}
            </Box>
          )}
        </Box>
      ))}
    </div>
  )
}

export default Listings
