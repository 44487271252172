import React, { useMemo } from 'react'
import { UserW2I9Form } from 'api/everify'
import { Card, Heading, Flex, Box, Field, Input, Icon } from '@workwhile/ui'
import styled from 'styled-components'
import { UserProfile } from 'api/user'
import { ApprovalForm } from './ApprovalForm'
import { RejectForm } from './RejectForm'
import { ProcessForm } from './ProcessForm'
import { FileTextIcon } from 'lucide-react'
import { format } from 'date-fns'

export function I9Form({
  user,
  data,
  showProcessForm,
}: {
  user: UserProfile
  data: UserW2I9Form
  showProcessForm?: boolean
}) {
  const docs = useMemo(() => {
    if (!!data) {
      return [
        'doc1',
        data.documentListType !== 'list_a' ? 'doc2' : null,
        'signature',
      ].filter(Boolean)
    }

    return []
  }, [data])

  return !!data && (
    <Card mt={3}>
      <Heading level={3}>I9 Form</Heading>
      <Flex>
        <Label>First Name:</Label> {data.firstName}
      </Flex>
      <Flex>
        <Label>Last Name:</Label> {data.lastName}
      </Flex>
      <Flex>
        <Label>Middle Name:</Label> {data.middleName}
      </Flex>
      <Flex>
        <Label>Date of Birth:</Label> {data.dob}
      </Flex>
      <Flex>
        <Label>SSN:</Label> {data.ssn}
      </Flex>
      <Flex>
        <Label>Email Address:</Label> {user.email}
      </Flex>
      <Flex>
        <Label>Phone Number:</Label> {user.phoneNumber}
      </Flex>
      <Flex>
        <Label>Citizenship:</Label> {data.citizenshipStatus}
      </Flex>
      {data.citizenshipStatus === 'permanent-resident' && (
        <Flex>
          <Label>USCIS or A-Number:</Label> {data.uscis}
        </Flex>
      )}
      <Flex>
        <Label>Start Date:</Label> {data.startDate}
      </Flex>
      <Flex>
        <Label>Document List:</Label>{' '}
        {data.documentListType === 'list_a'
          ? 'Both Identify/Employment Auth'
          : data.documentListType === 'list_b'
            ? 'Separate Identity/Employment Auth Documents'
            : 'See attached PDF'}
      </Flex>
      <Flex my={3} alignItems={'center'} backgroundColor={'offWhite'} p={3}>
        {data.documentListType ? (
          docs.map((doc) => {
            const url = `https://storage.cloud.google.com/outfielders/${user.id}/w2_${doc}.jpg`
            return (
              <a key={doc} href={url} target={'_blank'}>
                <Box>
                  <img width={'100%'} src={url} />
                </Box>
              </a>
            )
          })
        ) : (
          <a
            href={`https://storage.cloud.google.com/outfielders/${user.id}/w2_doc_I-9.pdf`}
            target={'_blank'}
          >
            <Icon icon={FileTextIcon} />
            &nbsp;HelloSign I9 Form
          </a>
        )}
      </Flex>
      {data.submittedAt && !data.processedAt ? (
        <>
          <ApprovalForm user={user} data={data} />
          {!data.verifiedAt ? <RejectForm user={user} /> : null}
        </>
      ) : null}
      {showProcessForm ? <ProcessForm user={user} data={data} /> : null}
    </Card>
  )
}

const Label = styled(Box).attrs({
  fontWeight: 'bold',
  width: 120,
})``
