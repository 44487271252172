import { useQuery } from '@tanstack/react-query'
import { getUserProfileById } from 'api/user'
import { userProfileByIdKey } from './keys'

export function useUserProfileById(userId: number) {
  return useQuery({
    queryKey: userProfileByIdKey(userId),
    queryFn: () => getUserProfileById(userId),
    retry: false,
  })
}
