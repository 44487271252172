import { useMutation } from '@tanstack/react-query'
import { acceptUserI9Documents, I9DocumentsApprovalRequest } from 'api/everify'

export function useAcceptI9Documents({
  userId,
  ...options
}: {
  userId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  return useMutation({
    mutationFn: (request: I9DocumentsApprovalRequest) =>
      acceptUserI9Documents(userId, request),
    ...options,
  })
}
