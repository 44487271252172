import zod from 'zod'

const documentSchema = zod.object({
  title: zod.string().min(1, 'Document Title is required'),
  issuingAuthority: zod.string().trim().min(1, 'Issuing Authority is required'),
  documentNumber: zod.string().trim().min(1, 'Document Number is required'),
  /** YYYY-MM-DD */
  expiryDate: zod.string().nullish().transform((date) => 
    date ? new Date(date).toISOString().split('T')[0] : null
  ),
})

export const approvalFormSchema = zod
  .object({
    documentListType: zod.string().min(1, 'Document List Type is required'),
    doc1: documentSchema.required(),
    doc2: documentSchema.optional(),
  })
  .refine(
    (data) => (data.documentListType === 'list_a' && !data.doc2) || (data.documentListType !== 'list_a' && !!data.doc2),
    {
      message: 'Document 2 is required for list B',
      path: ['doc2'],
    }
  )

type DocumentType = Required<zod.infer<typeof documentSchema>>

// have to manually define this since "strictNullChecks" is disabled in tsconfig
export interface ApprovalForm {
  documentListType: string
  doc1: DocumentType
  doc2?: DocumentType
}
