import React, { useCallback, useMemo, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { getRowStyles } from '../utils/util'
import { getShiftColumns } from './shifts'
import Loading from '../Shared/loading'
import { Box, Button, Heading, Input } from '@workwhile/ui'
import { useSearchParams } from 'react-router-dom'
import { useShifts } from 'queries/shifts'

const SingleShift = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const shiftId = useMemo(() => searchParams.get('id'), [searchParams])
  const shifts = useShifts('verbose', { shiftId, grouping: null }, { enabled: !!shiftId })

  const [inputShiftId, setInputShiftId] = useState(shiftId || '')
  const columns = useMemo(() => getShiftColumns('upcoming', null), [])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputShiftId(e.target.value)
    },
    []
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    setSearchParams({ id: inputShiftId })
  }

  return (
    <Box>
      <Box as={'form'} onSubmit={handleSubmit}>
        <Heading level={3}>Shift Information</Heading>
        <Input
          type="text"
          placeholder="Shift ID"
          value={inputShiftId}
          onChange={handleInputChange}
        />
        <Button loading={shifts.isFetching} ml={2} type={'submit'}>
          Load Shift
        </Button>
        {shifts.isFetching ? <Loading /> : null}
      </Box>
      {shifts.data ? (
        <Box mt={3}>
          <BootstrapTable
            striped
            hover
            keyField="id"
            data={shifts.data}
            columns={columns}
            rowStyle={getRowStyles}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default SingleShift
