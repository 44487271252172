import React from 'react'
import { useCall, useCallStateHooks } from '@stream-io/video-react-sdk'
import { Avatar, Box, Card, Flex, Heading, Text } from '@workwhile/ui'
import TimeAgo from 'react-timeago'
import { useUserProfileById } from 'queries/user'

export function CallQueueItem() {
  const call = useCall()
  const [, userId] = call.id.split('-')
  const { useCallSession, useParticipantCount } = useCallStateHooks()
  const callSession = useCallSession()
  const participantCount = useParticipantCount()
  const { data: user } = useUserProfileById(Number(userId))

  if (participantCount === 0) {
    return null
  }

  return (
    <Card mb={4} flexDirection={'row'}>
      <Box flex={1}>
        <Heading level={4} my={0}>
          Call Id: {call.id}
        </Heading>
        {callSession.started_at ? (
          <Text>
            Call started <TimeAgo date={callSession.started_at} />
          </Text>
        ) : null}
      </Box>
      {user ? (
        <Flex flexDirection={'column'} alignItems={'center'}>
          <Avatar
            size={'large'}
            user={{
              userId,
              name: user.name,
              photoUrl: user.profilePicUrl,
            }}
          />
          <Text>{user.name}</Text>
        </Flex>
      ) : null}
    </Card>
  )
}
