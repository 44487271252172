import React, { useEffect, useState } from 'react'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import graphql from '../../graphql'
import { filterShiftsLocally, getRowStyles } from '../utils/util'
import {
  companiesForFiltersQuery,
  getShiftColumns,
  shiftsQuery,
} from './shifts'
import moment from 'moment'
import ShiftsFilterBar2 from '../Shared/ShiftsFilterBar2'
import Loading from '../Shared/loading'
import { useLocation, useNavigate } from 'react-router'
import { Box, Button, Heading } from '@workwhile/ui'

const UpcomingShifts: React.FC = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const [filters, setFilters] = useState({
    companyIds: searchParams.get('companyIds')
      ? searchParams.get('companyIds').split(',').map(Number)
      : [],
    shiftId: searchParams.get('shiftId')
      ? Number(searchParams.get('shiftId'))
      : null,
    fromDate: searchParams.get('fromDate')
      ? moment(searchParams.get('fromDate')).format('YYYY-MM-DD')
      : null,
    toDate: searchParams.get('toDate')
      ? moment(searchParams.get('toDate')).format('YYYY-MM-DD')
      : null,
    market: searchParams.get('market'),
    showUnfilledShiftsOnly: false,
    showCancelledShiftsOnly: false,
  })

  const [fetching, setFetching] = useState(false)
  const [fetchingFilters, setFetchingFilters] = useState(false)
  const [shifts, setShifts] = useState([])
  const [columns, setColumns] = useState(getShiftColumns('upcoming', null))
  const [needToFetchOnFirstLoad, setNeedToFetchOnFirstLoad] = useState(
    Array.from(searchParams).length > 0
  )
  const [companyOptions, setCompanyOptions] = useState([])

  useEffect(() => {
    setFetchingFilters(true)
    graphql(companiesForFiltersQuery('upcoming')).then((response) => {
      const companies = response.data.data.admin.companiesForFilters
      setCompanyOptions(companies)
      setFetchingFilters(false)
    })
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (filters.companyIds.length > 0) {
      params.set('companyIds', filters.companyIds.join(','))
    }
    if (filters.shiftId) {
      params.set('shiftId', String(filters.shiftId))
    }
    if (filters.market) {
      params.set('market', String(filters.market))
    }
    if (filters.fromDate) {
      params.set('fromDate', filters.fromDate)
    }
    if (filters.toDate) {
      params.set('toDate', filters.toDate)
    }
    navigate({ search: params.toString() })
    if (needToFetchOnFirstLoad) {
      fetchShifts()
      setNeedToFetchOnFirstLoad(false)
    }
  }, [filters])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const fetchShifts = () => {
    setFetching(true)
    const fromDateToUse = !filters.fromDate
      ? moment().local().format('YYYY-MM-DD')
      : filters.fromDate
    const toDateToUse = !filters.toDate
      ? moment().local().add(2, 'days').format('YYYY-MM-DD')
      : filters.toDate
    graphql(
      shiftsQuery({
        type: 'upcoming',
        startDate: fromDateToUse,
        endDate: toDateToUse,
        companyIds: filters.companyIds,
        shiftId: filters.shiftId,
        market: filters.market,
        isUnfilled: filters.showUnfilledShiftsOnly
      })
    ).then((response) => {
      setShifts(response.data.data.admin.shifts)
      setFetching(false)
    })
  }

  return (
    <Box>
      <Box
        as={'form'}
        mb={4}
        onSubmit={(e) => {
          e.preventDefault()
          fetchShifts()
        }}
      >
        <Heading level={3}>Upcoming shifts ({'>'}30mins away)</Heading>
        {fetchingFilters && <Loading />}
        {!fetchingFilters && (
          <ShiftsFilterBar2
            {...filters}
            companyOptions={companyOptions}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
        <Box my={3}>Note: By default the dates filter for the next 2 days</Box>
        <Button type="submit" disabled={fetching}>
          Load shifts
        </Button>
        {fetching && <Loading />}
      </Box>
      {shifts && shifts.length > 0 && !fetching && (
        <BootstrapTable
          striped
          hover
          keyField="id"
          data={filterShiftsLocally(
            shifts,
            filters.showCancelledShiftsOnly,
            filters.showUnfilledShiftsOnly
          )}
          columns={columns}
          rowStyle={getRowStyles}
        />
      )}
    </Box>
  )
}

export default UpcomingShifts
