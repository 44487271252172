import React from 'react'
import { Button, Card, Heading, Text } from '@workwhile/ui'
import { UserProfile } from 'api/user'
import { useInvalidateUserW2I9Form, useRejectI9Documents } from 'queries/user'
import { showErrorToast } from 'components/utils/util'

export function RejectForm({ user }: { user: UserProfile }) {
  const invalidateI9Form = useInvalidateUserW2I9Form()
  const { mutate: rejectDocuments, isPending } = useRejectI9Documents({
    userId: Number(user.id),
    onSuccess: () => {
      invalidateI9Form(Number(user.id))
    },
    onError: (err: Error) => {
      showErrorToast(err.message)
    },
  })

  return (
    <Card mt={4}>
      <Heading my={0} mb={2} level={3}>
        Rejection
      </Heading>
      <Text>
        User will be required to resubmit their documents. Please be sure to
        highlight the issues on the call and provide user with detailed
        instructions.
      </Text>
      <Button
        my={3}
        variant={'secondary'}
        loading={isPending}
        onClick={rejectDocuments}
      >
        Reject I9 Documents
      </Button>
    </Card>
  )
}
