import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUnprocessedI9Forms } from 'api/everify/user_w2'
import { userUnprocessedI9Forms } from './keys'

export const useUnprocessedI9Forms = () => {
  return useQuery({
    queryKey: userUnprocessedI9Forms,
    queryFn: getUnprocessedI9Forms,
  })
}

export const useInvalidateUnprocessedI9Forms = () => {
  const queryClient = useQueryClient()

  return function invalidateUnprocessedI9Forms() {
    queryClient.invalidateQueries({
      queryKey: userUnprocessedI9Forms,
    })
  }
}
