import { WorkerAvailabilityRequest } from 'api/user'

export const userKey = ['user']
export const searchUserKey = (keyword: string) => [...userKey, keyword]
export const workerAvailabilityKey = ({
  shiftId,
  workerIds,
}: WorkerAvailabilityRequest) => [
  ...userKey,
  'workerAvailability',
  shiftId,
  workerIds?.join('|'),
]
export const userProfileKey = [...userKey, 'userProfile']
export const userProfileByIdKey = (userId: number) => [
  ...userKey,
  'userProfile',
  userId,
]
export const userW2I9FormKey = (userId: number) => [
  ...userKey,
  'userW2I9Form',
  userId.toString(),
]
export const userUnprocessedI9Forms = [...userKey, 'unprocessedI9Forms']
