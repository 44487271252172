import React from 'react'
import { useParams } from 'react-router'
import { EVerifyProvider } from './EVerifyProvider'
import { VideoBox } from './VideoBox'
import { Flex, Box } from '@workwhile/ui'
import { UserDetails } from './UserDetails'

export function CallRoom() {
  const { callId } = useParams<{ callId: string }>()
  const [, userId] = callId.split('-')

  return (
    <EVerifyProvider>
      <Flex>
        <Box flex={1 / 2}>
          <VideoBox callId={callId} />
        </Box>
        <Box flex={1 / 2}>
          <UserDetails userId={Number(userId)} />
        </Box>
      </Flex>
    </EVerifyProvider>
  )
}
