import React from 'react'
import { Button, Card, Heading, Message, Text } from '@workwhile/ui'
import { UserProfile } from 'api/user'
import {
  useInvalidateUnprocessedI9Forms,
  useInvalidateUserW2I9Form,
  useProcessI9Documents,
} from 'queries/user'
import { showErrorToast } from 'components/utils/util'
import { UserW2I9Form } from 'api/everify'
import { format } from 'date-fns'

export function ProcessForm({
  user,
  data,
}: {
  user: UserProfile
  data: UserW2I9Form
}) {
  const invalidateI9Form = useInvalidateUserW2I9Form()
  const invalidateUnprocessedForms = useInvalidateUnprocessedI9Forms()
  const { mutate: processDocuments, isPending } = useProcessI9Documents({
    userId: Number(user.id),
    onSuccess: () => {
      invalidateI9Form(Number(user.id))
      invalidateUnprocessedForms()
    },
    onError: (err: Error) => {
      showErrorToast(err.message)
    },
  })

  return (
    <Card mt={4}>
      <Heading my={0} mb={2} level={3}>
        Upload to eVerify Portal
      </Heading>
      {data.processedAt ? (
        <Message variant={'success'}>
          I9 Docs uploaded to eVerify at {format(data.processedAt, 'PPpp')}
        </Message>
      ) : (
        <>
          <Text>
            Once you manually upload the user form to eVerify portal, be sure to
            mark this form as Completed.
          </Text>
          <Button
            my={3}
            variant={'secondary'}
            loading={isPending}
            onClick={processDocuments}
          >
            Mark as Completed
          </Button>
        </>
      )}
    </Card>
  )
}
