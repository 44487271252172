export const SIGN_UP = '/signup'
export const LANDING = '/'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/pw-forget'
export const USERS = '/users/:keyword?'
export const SIGNIN = '/signin'
export const SHIFT_SEARCH = '/shifts'
export const SHIFT_DETAIL = '/shifts/:id'
export const UPCOMING_SHIFTS = '/upcoming_shifts'
export const ONGOING_SHIFTS = '/ongoing_shifts'
export const PAST_SHIFTS = '/past_shifts'
export const SINGLE_SHIFT = '/single_shift'
export const SCREEN = '/screen'
export const PAYMENTS = '/payments'
export const LISTINGS = '/listings'
export const LOGS = '/logs'
export const COMPANY = '/company'
export const BIZLEADS = '/bizleads'
export const WORKERBIZREFERRALS = '/workerbizreferrals'
export const MESSAGES2 = '/messages2'
export const POSITIONS = '/positions'
export const QUEST_DEFINITIONS = '/quest_definitions'
export const REVIEW = '/review'
export const REVIEW2 = '/review2'
export const CHECKR = '/checkr'
export const TIPS = '/tips'
export const POSITION_EDITOR = '/position_editor'
export const COMMANDS = '/commands'
export const SPREADSHEETS = '/spreadsheets'
export const SHIFT_ANALYTICS = '/shift_analytics'
export const LTA = '/lta'
export const EVERIFY = '/e-verify'
export const EVERIFY_UNPROCESS_FORMS = 'e-verify-unprocessed'
export const WORKER_SUPPLY = '/worker-supply'
