import snakecaseKeys from 'snakecase-keys'

export function toSnakecaseKeys(obj: object, options?: { deep?: boolean }) {
  return snakecaseKeys(
    { ...obj },
    {
      deep: options?.deep ?? false,
    }
  )
}
