import { useQuery } from '@tanstack/react-query'
import { getCallList } from 'actions/messaging_actions'
import { WorkShift } from 'api/typings'

type CallList = {
}[]

export function useCallList({ shiftId }: { shiftId: WorkShift['id'] | string }) {
  return useQuery<CallList>({
    queryKey: ['shift', shiftId, 'callList'],
    queryFn: async () => getCallList(shiftId).then((result) => result.data),
    enabled: false,
    retry: false,
  })
}
