import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
} from 'react'
import { useUserProfile } from 'queries/user'
import { getStreamToken } from 'api/everify'
import { config } from 'config'
import { StreamVideoClient } from '@stream-io/video-react-sdk'

export enum EVerifyStateEnum {}

export interface EVerifyContextValues {
  client: StreamVideoClient
}

export const EVerifyContext = createContext<EVerifyContextValues | null>(null)

export function EVerifyProvider({ children }: PropsWithChildren) {
  const { data: user } = useUserProfile()
  const [client, setClient] = useState<StreamVideoClient>()

  useEffect(() => {
    if (user) {
      const myClient = new StreamVideoClient({
        apiKey: config.getStreamConfig.apiKey,
        user: { id: user.id.toString(), name: user.name.split(' ')[0] },
        tokenProvider: () => getStreamToken(),
      })
      setClient(myClient)
      return () => {
        myClient.disconnectUser()
        setClient(undefined)
      }
    }
  }, [user])

  const value = useMemo(() => ({ client: client! }), [client])

  return (
    <EVerifyContext.Provider value={value}>
      {client ? children : null}
    </EVerifyContext.Provider>
  )
}
