import React, { useState } from 'react'
import { useUnprocessedI9Forms } from 'queries/user/useUnprocessedI9Forms'
import { Flex, Box, Loading, Card, Text } from '@workwhile/ui'
import { DetailView } from './DetailView'

export default function UnprocessedI9FormsPage() {
  const { data: unprocessedForms, isLoading: isLoadingUnprocessedForms } =
    useUnprocessedI9Forms()
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)

  return (
    <Flex>
      <Box width={1 / 3} p={3}>
        {isLoadingUnprocessedForms ? (
          <Loading />
        ) : (
          <Card>
            {unprocessedForms?.map((form) => (
              <Box
                mb={3}
                p={3}
                backgroundColor={'offWhite'}
                key={form.userId}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedUserId(form.userId)}
                borderColor={
                  selectedUserId === form.userId ? 'primary' : 'transparent'
                }
                borderStyle={'solid'}
                borderWidth={2}
              >
                User: {form.firstName} {form.lastName} ({form.userId})
              </Box>
            ))}
            {unprocessedForms?.length === 0 ? (
              <Text>There are no pending forms to process.</Text>
            ) : null}
          </Card>
        )}
      </Box>
      <Box width={2 / 3} p={3}>
        {!selectedUserId ? (
          <Text>Please select a form to view details</Text>
        ) : (
          <DetailView userId={selectedUserId} />
        )}
      </Box>
    </Flex>
  )
}
