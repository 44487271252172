import React, { useEffect, useState } from 'react'
import graphql from '../../../graphql'
import { Work, WorkShift } from '../../Shared/common_defs'
import Loading from '../../Shared/loading'
import WorkerBasicInfo from './WorkerBasicInfo'

interface Props {
  shift: WorkShift
  fetchWorkStats: () => void
}

const WorkerList = ({ shift, fetchWorkStats }: Props) => {
  // grab list of work associated with this shift
  // for each work display WorkerBasicInfo
  const [fetchingWorkers, setFetchingWorkers] = useState(false)
  const [workItems, setWorkItems] = useState(null)

  const workerListQuery = (shiftId) => {
    return `
            admin { 
                shift (shiftId:${shiftId}) {
                    work (status:"all") {
                        id, status, confirmedAt,
                        worker {id, name, checkrStatus, checkrMvrStatus}
                        shift { position { isDrivingPosition } }
                        isReplaceable
                        replacedWork {
                          id,
                          worker {
                            id
                            name
                          }
                        }
                        replacedAt
                    }
                }
            }`
  }

  useEffect(() => {
    // componentDidMount - use the work item we already asked for in shift query
    setWorkItems(sortWorkers(shift.work))
  }, [])

  useEffect(() => {
    // when the shift prop changes (i.e. worker stats at level above changed, query again for updated info on our workers)
    fetchWorkers(true)
  }, [shift])

  // fetchWorkers used in actions in child components that require a refresh of the top level worker data
  const fetchWorkers = (showLoading: boolean) => {
    if (showLoading) {
      setFetchingWorkers(true)
    }
    graphql(workerListQuery(shift.id))
      .then((response) => {
        const workData = response.data.data.admin.shift.work
        if (workData) {
          setWorkItems(sortWorkers(workData))
        }
        setFetchingWorkers(false)
      })
      .catch(() => {
        setFetchingWorkers(false)
      })
  }

  if (fetchingWorkers) {
    return <Loading type="button" />
  }

  if (!workItems || workItems.length === 0) {
    return <p>Could not find workers for this shift.</p>
  }

  return workItems.map((work) => (
    <WorkerBasicInfo
      work={work}
      shift={shift}
      fetchWorkStats={fetchWorkStats}
      fetchWorkers={fetchWorkers}
    />
  ))
}

export default WorkerList

const sortWorkers = (people): Array<Work | null> => {
  let localArray = [...people]
  localArray.sort(function (personA, personB) {
    let textA = personA.status
    let textB = personB.status
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  return localArray
}
