import { useQuery } from '@tanstack/react-query'
import api from 'api'
import { WorkShift } from 'api/typings'

export type WorkerEligibility = {
  /** Total number of valid workers found within radius (miles) */
  total: number,
  /** From total, number of workers able to accept a listing */
  eligible: number,
  /** Aggregation of reasons across total which prevent workers from accepting a listing */
  ineligibility_reasons: Record<string, number>
  /** Applied radius (miles) from the listing override or the default (75) */
  listing_radius: number
}

export function useWorkerEligibility(shiftId: WorkShift['id'] | string) {
  return useQuery<WorkerEligibility>({
    queryKey: ['shift', shiftId, 'eligibility'],
    queryFn: async () =>
      api.get(`/admin/shift/${shiftId}/eligibility`).then((result) => result.data),
    enabled: !!shiftId,
    retry: false,
  })
}
