import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { defs } from '../../Shared/defs'
import Select, { OptionTypeBase } from 'react-select'
import makeAnimated from 'react-select/animated'
import { useSearchParams } from 'react-router-dom'
import useCompanies from 'queries/company/useCompanies'
import { Box, Button, Flex, Heading, Input, Label, Text } from '@workwhile/ui'
import { Company, WorkShift } from 'api/typings'
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { DATE_INPUT_FORMAT, GetShiftsOptions } from 'api/shifts'
import { useDebounceFn } from 'ahooks'
import moment from 'moment'

const animatedComponents = makeAnimated()

export const QueryParams = {
  range: 'range',
  shiftId: 'shiftId',
  fromDate: 'fromDate',
  toDate: 'toDate',
  companyIds: 'companyIds',
  market: 'market',
  showCancelledShiftsOnly: 'showCancelledShiftsOnly',
  showUnfilledShiftsOnly: 'showUnfilledShiftsOnly',
  companyGroupId: 'companyGroupId',
  minUnfilledSpots: 'minUnfilledSpots',
  groupBy: 'groupBy',
  maxFillPercent: 'maxFillPercent',
} as const

export const mapQueryToFilters = (query: URLSearchParams): GetShiftsOptions => {
  const range = (
    ['upcoming', 'past', 'ongoing'].includes(query.get(QueryParams.range))
      ? query.get(QueryParams.range)
      : 'upcoming'
  ) as GetShiftsOptions['grouping']

  return {
    grouping: range,
    startsAt: query.get(QueryParams.fromDate),
    endsAt: query.get(QueryParams.toDate),
    companyIds: query.get(QueryParams.companyIds)?.split(','),
    shiftId: query.get(QueryParams.shiftId),
    market: query.get(QueryParams.market),
    isUnfilled:
      range === 'upcoming'
        ? query.get(QueryParams.showUnfilledShiftsOnly) !== 'false'
        : query.get(QueryParams.showUnfilledShiftsOnly) === 'true',
  }
}

const toCompanyOption = (company: Company) =>
  company
    ? {
        label: `${company.name} (${company.id})`,
        value: company.id,
      }
    : null

const toCompanyGroupOption = (company: Company) =>
  company
    ? {
        label: `${company.name.split(' - ')[0]} (${company.companyGroupId})`,
        value: company.companyGroupId,
      }
    : null

const getShiftOptions = (options: SelectOptions, shift: WorkShift) => {
  if (!options.markets.some(({ value }) => value === shift.market)) {
    options.markets.push({
      label: shift.market.toUpperCase(),
      value: shift.market,
    })
  }

  if (!options.shiftIds.some(({value}) => value === shift.id)) {
    options.shiftIds.push({
      label: shift.id,
      value: shift.id,
    })
  }

  return options
}

const getUniqueCompanyGroupIds = (groupIds, company: Company) => {
  if (
    !company?.companyGroupId ||
    groupIds.some(({ value }) => value == company.companyGroupId)
  ) {
    return groupIds
  }

  return groupIds.concat(toCompanyGroupOption(company))
}

type SelectOptions = {
  markets: OptionTypeBase[]
  shiftIds: OptionTypeBase[]
}

const SearchFacet = ({
  children,
  label,
}: React.PropsWithChildren<{
  label?: string
}>) => (
  <Flex flexDirection="column" pt={2} mr="2" as="label">
    <Text as="span" color={defs.mediumBlack}>
      &
    </Text>

    {label && (
      <Text as="span" style={{ fontSize: 12 }}>
        {label}
      </Text>
    )}
    {children}
  </Flex>
)

type Range = 'upcoming' | 'past' | 'ongoing'
const rangeOptions: { label: string; value: Range }[] = [
  { label: 'Past shifts', value: 'past' },
  { label: 'Ongoing shifts', value: 'ongoing' },
  { label: 'Upcoming shifts', value: 'upcoming' },
]
const rangeCaptions: Record<Range, string> = {
  upcoming: '> 30 mins away + 3 days',
  ongoing: '+/- 30 min from now',
  past: 'Default: Past 2 days',
}

const ShiftsFilterBar = ({ shifts }: { shifts: UseQueryResult }) => {
  const [query, setQuery] = useSearchParams()
  const filters = mapQueryToFilters(query)
  const queryClient = useQueryClient()
  const companies = useCompanies('ids', filters)

  const options: SelectOptions = useMemo(
    () =>
      (queryClient.getQueryData<WorkShift[]>(['shifts', 'slim', filters]) || []).reduce(
        getShiftOptions,
        {
          markets: [],
          shiftIds: [],
        } satisfies SelectOptions
      ),
    [queryClient, filters]
  )

  const handleCheckbox = useCallback(
    (key) => () => {
      setQuery((params) => {
        if (params.get(key) === 'true') {
          params.delete(key)
        } else {
          params.set(key, 'true')
        }

        return params
      })
    },
    [setQuery]
  )

  const handleSelect = useCallback(
    (key: keyof typeof QueryParams) => (selection: OptionTypeBase | null) => {
      setQuery((params) => {
        if (selection?.value) {
          params.set(QueryParams[key], selection?.value)

          /** When selecting a company group ID, set all company IDs in order to hit API */
          if (key === QueryParams.companyGroupId) {
            params.set(
              QueryParams.companyIds,
              companies.data
                .reduce((all, company) => {
                  if (company.companyGroupId == selection?.value) {
                    return all.concat(company.id)
                  }
                  return all
                }, [])
                .toString()
            )
          }

          /** Clear custom dates when changing the range type */
          if (key === QueryParams.range) {
            params.delete(QueryParams.fromDate)
            params.delete(QueryParams.toDate)
          }
        } else {
          params.delete(QueryParams[key])

          /** Remove all company IDs when clearing the company group ID */
          if (key === QueryParams.companyGroupId) {
            params.delete(QueryParams.companyIds)
          }
        }

        return params
      })
    },
    [setQuery]
  )

  const handleMultiSelect = useCallback(
    (key: keyof typeof QueryParams) => (selectedOptions) => {
      setQuery((params) => {
        if (selectedOptions?.length) {
          params.set(
            QueryParams[key],
            selectedOptions?.map((option) => option.value)
          )
        } else {
          params.delete(QueryParams[key])
        }
        return params
      })
    },
    [setQuery]
  )

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target

      setQuery((params) => {
        if (value?.length) {
          params.set(name, value.toString())
        } else {
          params.delete(name)
        }
        return params
      })
    },
    [setQuery]
  )

  const handleSelectInput = useCallback(
    (name) => (value) =>
      handleInput({
        target: { name, value },
      } as ChangeEvent<HTMLInputElement>),
    [handleInput]
  )

  const handleUnfilled = useCallback(() => {
    setQuery((params) => {
      const range = params.get(QueryParams.range) || 'upcoming'

      if (range === 'upcoming') {
        /** Show unfilled shifts by default for upcoming shifts */
        if (params.get(QueryParams.showUnfilledShiftsOnly) === 'false') {
          params.delete(QueryParams.showUnfilledShiftsOnly)
        } else {
          params.set(QueryParams.showUnfilledShiftsOnly, 'false')
        }
      } else {
        /** Other ranges show all shifts by default */
        handleCheckbox(QueryParams.showUnfilledShiftsOnly)()
      }

      return params
    })
  }, [setQuery, handleCheckbox])

  const rangeCaption = rangeCaptions[query.get(QueryParams.range)]

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault()
        shifts.refetch()
      }}
    >
      <Flex justifyContent="space-between">
        <Heading level="1">Search shifts</Heading>
        <Flex alignItems="center">
          <Text as="label" htmlFor="shiftId" mr="2">
            Shift ID
          </Text>
          <Select
            id="shiftId"
            isDisabled={!options.shiftIds}
            isSearchable
            isClearable
            value={{ label: query.get(QueryParams.shiftId), value: query.get(QueryParams.shiftId) }}
            options={options.shiftIds}
            components={animatedComponents}
            name={QueryParams.shiftId}
            placeholder="Shift ID"
            onChange={handleSelect('shiftId')}
            styles={{
              container: (provided) => ({
                ...provided,
                width: 220,
              }),
            }}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        p={defs.paddingL}
        my={defs.paddingL}
        borderColor={defs.veryLightBlack}
        bg="#fafafa"
        alignItems="start"
      >
        <SearchFacet label="Date range">
          <Box>
            <Select
              isMulti={false}
              value={
                rangeOptions.find(
                  ({ value }) => value == query.get(QueryParams.range)
                ) || rangeOptions[2]
              }
              options={rangeOptions}
              components={animatedComponents}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: 180,
                }),
              }}
              name={QueryParams.range}
              placeholder="Date range"
              // @ts-ignore
              onChange={handleSelect('range')}
            />
            {rangeCaption && (
              <Text mt="1" style={{ fontStyle: 'italic' }}>
                {rangeCaption}
              </Text>
            )}
          </Box>
        </SearchFacet>
        {query.get(QueryParams.range) !== 'ongoing' && (
          <SearchFacet key={query.get(QueryParams.range)}>
            <Box as="label">
              <Text style={{ fontSize: 12 }}>From</Text>
              <Input
                id={QueryParams.fromDate}
                type="date"
                name={QueryParams.fromDate}
                defaultValue={
                  query.get(QueryParams.range) === 'past'
                    ? moment().subtract(2, 'days').format(DATE_INPUT_FORMAT)
                    : moment().format(DATE_INPUT_FORMAT)
                }
                value={query.get(QueryParams.fromDate)}
                onChange={handleInput}
              />
            </Box>
            <Box mt="2">
              <Box as="label">
                <Text style={{ fontSize: 12 }}>To</Text>
                <Input
                  id={QueryParams.toDate}
                  type="date"
                  name={QueryParams.toDate}
                  defaultValue={
                    query.get(QueryParams.range) === 'past'
                      ? moment().format(DATE_INPUT_FORMAT)
                      : moment().add(3, 'days').format(DATE_INPUT_FORMAT)
                  }
                  value={query.get(QueryParams.toDate)}
                  onChange={handleInput}
                />
              </Box>
            </Box>
          </SearchFacet>
        )}
        <SearchFacet label="Company Group">
          <Select
            isDisabled={!companies.data}
            isSearchable
            isClearable
            value={
              query.get(QueryParams.companyGroupId)
                ? toCompanyGroupOption(
                    companies.data?.find(
                      ({ companyGroupId }) =>
                        query.get(QueryParams.companyGroupId) == companyGroupId
                    )
                  )
                : null
            }
            options={companies.data
              ?.reduce(getUniqueCompanyGroupIds, [])
              ?.sort((a, b) => (a.label < b.label ? -1 : 1))}
            components={animatedComponents}
            styles={{
              container: (provided) => ({
                ...provided,
                width: 220,
              }),
            }}
            name={QueryParams.companyGroupId}
            placeholder="Company Group"
            onChange={handleSelect('companyGroupId')}
          />
        </SearchFacet>
        <SearchFacet label="Companies">
          <Select
            isDisabled={!companies.data}
            isMulti
            isSearchable
            isClearable
            value={(companies.data || [])
              .filter(({ id }) =>
                query.get(QueryParams.companyIds)?.split(',')?.includes(id)
              )
              .map(toCompanyOption)}
            options={companies.data
              ?.filter((company) =>
                query.get(QueryParams.companyGroupId)
                  ? company.companyGroupId ==
                    query.get(QueryParams.companyGroupId)
                  : true
              )
              .map(toCompanyOption)
              .sort((a, b) => (a.label < b.label ? -1 : 1))}
            components={animatedComponents}
            styles={{
              container: (provided) => ({
                ...provided,
                width: 220,
              }),
            }}
            name={QueryParams.companyIds}
            placeholder="Companies"
            onChange={handleMultiSelect('companyIds')}
          />
        </SearchFacet>
        <SearchFacet label="Market">
          <Select
            isDisabled={!options.markets}
            isSearchable
            isClearable
            value={query.get(QueryParams.market) ? {
              label: query.get(QueryParams.market).toUpperCase(),
              value: query.get(QueryParams.market)
            } : null}
            options={options.markets?.sort((a, b) => a.label < b.label ? -1 : 1)}
            components={animatedComponents}
            styles={{
              container: (provided) => ({
                ...provided,
                width: 100,
              }),
            }}
            name={QueryParams.market}
            placeholder="Market"
            onChange={handleSelect('market')}
          />
        </SearchFacet>

        <SearchFacet label="Min Unfilled">
          <Input
            id={QueryParams.minUnfilledSpots}
            type="number"
            name={QueryParams.minUnfilledSpots}
            placeholder='0'
            value={query.get(QueryParams.minUnfilledSpots) || ''}
            onChange={handleInput}
            min={0}
            style={{ width: 80 }}
          />
        </SearchFacet>
        <SearchFacet label="Max Fill %">
          <Input
            id={QueryParams.maxFillPercent}
            type="number"
            name={QueryParams.maxFillPercent}
            placeholder="100"
            value={query.get(QueryParams.maxFillPercent) || ''}
            onChange={handleInput}
            min={0}
            style={{ width: 80 }}
          />
        </SearchFacet>

        <SearchFacet label="Filters">
          <Flex as="label" alignItems="center">
            <input
              style={{ height: 20, width: 20, accentColor: defs.wwDarkTeal }}
              type="checkbox"
              name={QueryParams.showUnfilledShiftsOnly}
              onChange={handleUnfilled}
              checked={
                filters.grouping === 'upcoming'
                  ? query.get(QueryParams.showUnfilledShiftsOnly) !== 'false'
                  : query.get(QueryParams.showUnfilledShiftsOnly) === 'true'
              }
            />
            <Text ml="2">Unfilled shifts only</Text>
          </Flex>
          <Flex as="label" alignItems="center" mt="2">
            <input
              style={{ height: 20, width: 20, accentColor: defs.wwDarkTeal }}
              type="checkbox"
              name={QueryParams.showCancelledShiftsOnly}
              onChange={handleCheckbox(QueryParams.showCancelledShiftsOnly)}
              checked={
                query.get(QueryParams.showCancelledShiftsOnly) === 'true'
              }
            />
            <Text ml="2">Cancelled shifts only</Text>
          </Flex>
        </SearchFacet>
      </Flex>

      <Flex alignItems="end" justifyContent="space-between">
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <Heading level="4">Group by</Heading>
            <Flex>
              <Label
                as="button"
                type="button"
                name="groupBy"
                value="date"
                style={{ cursor: 'pointer' }}
                variant={
                  query.get(QueryParams.groupBy) === 'date' || !query.get(QueryParams.groupBy)
                    ? 'primary'
                    : 'none'
                }
                mr="2"
                onClick={handleInput}
              >
                Date {query.get(QueryParams.range) === 'past' ? 'desc' : 'asc'}
              </Label>
              <Label
                as="button"
                type="button"
                name="groupBy"
                value="companyGroup"
                style={{ cursor: 'pointer' }}
                variant={
                  query.get('groupBy') === 'companyGroup' ? 'primary' : 'none'
                }
                mr="2"
                onClick={handleInput}
              >
                Company group
              </Label>
              <Label
                as="button"
                type="button"
                name="groupBy"
                value="market"
                style={{ cursor: 'pointer' }}
                variant={query.get('groupBy') === 'market' ? 'primary' : 'none'}
                mr="2"
                onClick={handleInput}
              >
                Market
              </Label>
            </Flex>
          </Box>
        </Flex>

        <Button
          type="submit"
          disabled={shifts.isFetching}
          width="200px"
          loading={shifts.isFetching}
        >
          {shifts.isFetching ? 'Loading shifts' : 'Load shifts'}
        </Button>
      </Flex>
    </Box>
  )
}

export default ShiftsFilterBar
