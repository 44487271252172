import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { getShiftById } from 'api/shifts'
import { WorkShift } from 'api/typings'
import { mergeDeepRight } from 'ramda'

const QUERIES = {
  detail: `{
      id,
      market,
      cancelledAt, isTryout, 
      startsAt, endsAt, lunchLength,
      supervisor, supervisorPhone,
      company { name, id },
      location { 
        address { street, city, state, zip, lat, long, timezone } 
        clockInLat,
        clockInLong,
        clockInRadius,
      },
      listing {
          id,
          tier,
          tierTransitions,
          totalBonus,
          totalPay,
          freeze
      },
      position {
          id,
          name,
          isDrivingPosition,
          needsCar,
          needsFullTime,
          needsLicense,
          needsW2,
          mustHaveRequirements {
              id,
              name,
          }
      }
  }`,

  /** Shift fulfillment statistics */
  fill: `{
    id, 
    workersNeeded, numCushionWorkersNeeded, numWorkersScheduled,
    numWorkersStarted, numWorkersFinished(excludeEmployerCancelled:true), 
    numWorkersRejected, numWorkersRemoved,
    rosters {
      id, isSpecificWorkerRequest, name,
      workers {
          id, name, profilePicUrl
      }
    },
    rostersHoldExpiresAt,
    listings { numWaitlist },
  }`,

  /** Basic fill stats and tier transition information */
  listingTiers: `{
    id, startsAt, 
    numWorkersFilled, workersNeeded, numCushionWorkersNeeded,
    listings { id, tier, tierTransitions, numWaitlist },
    location { address { timezone } },
  }`,

  /** Pay and bonus-related information */
  pay: `{
    id, 
    numWorkersFilled, 
    pay, payRate, payLumpSum,
    shiftBonuses { amount, createdAt, companyPaid, isDynamicBonus, isNewWorkerBonus },
    location { address { timezone } },
  }`,

  /** List of scheduled workers and their status */
  work: `{
    work (status:"all") {
      id, status, confirmedAt,
      worker { id, name, checkrStatus, checkrMvrStatus }
      shift { position { isDrivingPosition } }
      isReplaceable
      replacedWork {
        id,
        worker {
          id
          name
        }
      }
      replacedAt
    }
  }`,
}

/**
 * Merges together all shift-related data for this shift ID even if different GQL queries are used
 * @example useShiftDetails(shiftId, 'listingTiers', { enabled: false })
 */
export function useShiftDetails(
  shiftId: WorkShift['id'] | string,
  queryKey?: keyof typeof QUERIES, 
  options?: Partial<UseQueryOptions>) {
  const queryClient = useQueryClient()

  return useQuery<WorkShift>({
    queryKey: ['shift', shiftId, queryKey].filter(Boolean),
    queryFn: async () => getShiftById(shiftId, QUERIES[queryKey]),
    enabled: !!shiftId,
    retry: false,
    /** Merge together all data related to this shift id */
    structuralSharing: (prev = {}, next = {}) => mergeDeepRight<WorkShift, WorkShift>(prev, next),
    /** Do not remove existing data when additional data is fetched */
    placeholderData: keepPreviousData,
    /** Pre-populate details from search page */
    initialData: queryClient
      .getQueriesData<WorkShift[]>({ queryKey: ['shifts'] })
      .reduce(
        (_all, [_qk, shifts]): WorkShift =>
          shifts?.find((shift) => shift.id === shiftId),
        undefined as WorkShift
      ),
    ...options,
  })
}
