import React from 'react'
import { EVerifyProvider } from './EVerifyProvider'
import { CallQueue } from './CallQueue'

export function EVerifyPage() {
  return (
    <EVerifyProvider>
      <CallQueue />
    </EVerifyProvider>
  )
}
