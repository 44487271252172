import React, { useState, useCallback, useMemo } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import api from '../../../api';
import { GOOGLE_MAPS_API_KEY } from 'env';
import { Box, Card, Flex, Loading, Message, Input, Button, Text } from '@workwhile/ui';
import { SearchIcon } from 'lucide-react';
import { Libraries } from '@react-google-maps/api';

// Define libraries outside of the component
const libraries: Libraries = ['places'];

interface WorkerLocation {
    lat: number;
    lng: number;
}

interface WorkerData {
    workers_data: {
        num_total_workers: number;
        num_worked_30_days: number;
    };
    coordinates: [string, string][];
}

const WorkerSupply: React.FC = () => {
    const [address, setAddress] = useState('');
    const [mapCenter, setMapCenter] = useState<WorkerLocation>({ lat: 0, lng: 0 });
    const [workerData, setWorkerData] = useState<WorkerData | null>(null);
    const [markerOptions, setMarkerOptions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    const handleSelect = async (value: string) => {
        setIsLoading(true);
        setError(null);
        try {
            const results = await geocodeByAddress(value);
            const latLng = await getLatLng(results[0]);
            setAddress(value);
            setMapCenter(latLng);
            await fetchWorkerData(value);
        } catch (error) {
            setError('Error fetching data. Please try again.');
            console.error('Error in handleSelect:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchWorkerData = async (location: string) => {
        try {
            const response = await api.get(`/admin/worker_supply?location=${encodeURIComponent(location)}`);
            setWorkerData(response.data);
        } catch (error) {
            setError('Error fetching worker data. Please try again.');
            console.error('Error fetching worker data:', error);
        }
    };

    const mapOptions = {
        styles: [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }]
            }
        ]
    };

    const onLoad = useCallback(() => {
        setMarkerOptions({
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 3,
                fillColor: "#FF0000",
                fillOpacity: 0.5,
                strokeWeight: 0
            }
        });
    }, []);

    if (loadError) {
        return <Message variant={'error'}>Map cannot be loaded right now, sorry.</Message>
    }

    if (!isLoaded) {
        return <Loading />
    }

    return (
        <Flex flexDirection={'column'} height={'100vh'} m={-20}>
            <Flex as={'form'} onSubmit={(e) => { e.preventDefault(); handleSelect(address); }} p={3} px={4} backgroundColor={'offWhite'}>
                <Box width={400} mr={2}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <Input
                                    {...getInputProps({
                                        placeholder: 'Enter a city or zip code',
                                        className: 'location-search-input',
                                    })}
                                    block
                                    disabled={isLoading}
                                    icon={<SearchIcon size={18} />}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </Box>
                <Button width={150} type="submit" disabled={isLoading}>
                    Search
                </Button>
            </Flex>
            {isLoading ? (
                <Loading />
            ) : (
                <Box flex={1} overflow={'auto'}>
                    {error ? <Message variant={'error'}>{error}</Message> : null}
                    {workerData && (
                        <Card m={4} p={4} backgroundColor={'offWhite50'}>
                            <Flex justifyContent="space-around" mb={4}>
                                <Flex flexDirection="column" alignItems="center">
                                    <Text fontSize={40} fontWeight="bold">
                                        {workerData.workers_data.num_total_workers}
                                    </Text>
                                    <Text fontSize={14} color="gray.600">
                                        Total number of workers within 75 miles
                                    </Text>
                                </Flex>
                                <Flex flexDirection="column" alignItems="center">
                                    <Text fontSize={40} fontWeight="bold">
                                        {workerData.workers_data.num_worked_30_days}
                                    </Text>
                                    <Text fontSize={14} color="gray.600">
                                        Workers worked in last 30 days
                                    </Text>
                                </Flex>
                            </Flex>
                            <Box height={400}>
                                <GoogleMap
                                    onLoad={onLoad}
                                    center={mapCenter}
                                    zoom={10}
                                    mapContainerStyle={{ width: '100%', height: '100%' }}
                                    options={mapOptions}
                                >
                                    <MarkerClusterer>
                                        {(clusterer) => (
                                            <>
                                                {workerData.coordinates.map((coordinate, index) => (
                                                    <Marker
                                                        key={index}
                                                        position={{
                                                            lat: parseFloat(coordinate[1]),
                                                            lng: parseFloat(coordinate[0])
                                                        }}
                                                        options={markerOptions}
                                                        clusterer={clusterer}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </MarkerClusterer>
                                </GoogleMap>
                            </Box>
                        </Card>
                    )}
                </Box>
            )}
        </Flex>
    );
};

export default WorkerSupply;
