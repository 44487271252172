import { useContext } from 'react';
import { EVerifyContext } from './EVerifyProvider';

export function useEVerify() {
  const context = useContext(EVerifyContext);
  if (!context) {
    throw new Error('useEVerify must be used within an EVerifyProvider');
  }
  return context;
}
