import { Company, WorkShift } from "api/typings";
import graphql from "graphql";
import moment from "moment";

export const DATE_INPUT_FORMAT = 'yyyy-MM-DD'

export type GetShiftsOptions = {
    grouping?: 'upcoming' | 'past' | 'ongoing'
    startsAt?: WorkShift['startsAt'] | Date
    endsAt?: WorkShift['endsAt'] | Date
    isUnfilled?: boolean
    companyIds?: Company['id'][]
    market?: WorkShift['market']
    shiftId?: WorkShift['id'] | string
}

type GetShiftsResult = { data: { admin: { shifts: WorkShift[] } }}

export const getShifts = ({
    grouping = 'upcoming', 
    market, 
    companyIds, 
    shiftId, 
    startsAt, 
    endsAt, 
    isUnfilled
}: GetShiftsOptions, query: string) => graphql<GetShiftsResult>(`
    admin { 
        shifts (
            ${ grouping ? `, grouping: "${grouping}"` : ''}
            ${ startsAt ? `, startDate: "${moment(startsAt).startOf('day').format(DATE_INPUT_FORMAT)}"` : ''}
            ${ endsAt ? `, endDate: "${moment(endsAt).startOf('day').add(1, 'day').format(DATE_INPUT_FORMAT)}"` : ''}
            ${ companyIds?.length ? `, companyIds: [${companyIds.map(Number)}]` : ''}
            ${ market ? `, market: "${market}"` : ''}
            ${ shiftId ? `, shiftId: "${shiftId}"` : ''}
            ${ isUnfilled ? `, isUnfilled: true` : ''}
        ) ${query}
    }`).then(result => result.data.data.admin.shifts)

