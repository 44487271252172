import { useMutation } from '@tanstack/react-query'
import { rejectUserI9Documents } from 'api/everify'

export function useRejectI9Documents({
  userId,
  ...options
}: {
  userId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  return useMutation({
    mutationFn: () => rejectUserI9Documents(userId),
    ...options,
  })
}
