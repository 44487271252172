import { useQuery } from '@tanstack/react-query'
import { getUserProfile } from 'api/user'
import { userProfileKey } from './keys'

export function useUserProfile() {
  return useQuery({
    queryKey: userProfileKey,
    queryFn: getUserProfile,
  })
}
