import React, { useEffect, useState } from 'react'
import { useEVerify } from './useEVerify'
import { Call, StreamCall, StreamVideoEvent } from '@stream-io/video-react-sdk'
import { Box, Heading, Link, Text } from '@workwhile/ui'
import { CallQueueItem } from './CallQueueItem'
import { useInterval } from 'ahooks'

export function CallQueue() {
  const { client } = useEVerify()
  const [calls, setCalls] = useState<Call[]>([])

  const fetchCalls = () => {
    client
      .queryCalls({
        filter_conditions: {
          type: { $eq: 'default' },
          ongoing: true,
        },
        limit: 10,
        sort: [{ field: 'starts_at', direction: 1 }],
        watch: true,
      })
      .then(({ calls: callList }) => {
        setCalls(callList)
        // send desktop notifications if hidden
        if (document.hidden && callList.length > 0) {
          const notification = new Notification('New eVerify Video Call', {
            body: 'There are user(s) waiting on an eVerify Video Call, please promptly join.',
          })
          notification.onclick = (event) => {
            event.preventDefault()
            if (document.visibilityState === 'hidden') {
              window.focus()
              notification.close()
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching calls:', error)
      })
  }

  useInterval(fetchCalls, 10000)

  // notification permission
  useEffect(() => {
    if ('Notification' in window) {
      const requestPermission = async () => {
        try {
          const result = await Notification.requestPermission()
          if (result === 'granted') {
            console.log('Notification permission granted.')
          } else {
            console.log('Notification permission denied.')
          }
        } catch (error) {
          console.error('Error requesting notification permission:', error)
        }
      }

      if (Notification.permission !== 'granted') {
        requestPermission()
      }
    }
  }, [])

  useEffect(() => {
    // fetch initial calls
    fetchCalls()

    // subscribe to events
    const unsubscribe = client.on('all', (event: StreamVideoEvent) => {
      if (
        [
          'call.created',
          'call.session_started',
          'call.ended',
          'call.session_ended',
        ].includes(event.type)
      ) {
        fetchCalls()
      }
    })
    return unsubscribe
  }, [])

  return (
    <Box>
      <Heading level={2}>Call Queue</Heading>
      {calls.length === 0 ? (
        <Text>No calls in queue</Text>
      ) : (
        calls.map((call) => (
          <Link
            key={`${call.id}-${call.state.callingState}`}
            to={`/e-verify/${call.id}`}
          >
            <StreamCall call={call}>
              <CallQueueItem />
            </StreamCall>
          </Link>
        ))
      )}
    </Box>
  )
}
